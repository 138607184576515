<template>
  <div class="invest container">
    <div class="title">{{ $t('app.invest.invest') }}</div>
    <div class="header">
      <!--      <section>-->
      <!--        <div>当前总量(wel)</div>-->
      <!--        <span>{{ itemData.total | numFilter5 }}</span>-->
      <!--      </section>-->
      <div>
        <div>
          <div> {{ $t('news.nowtotal') }}</div>
          <span>{{ itemData.total | noZero }}</span>
        </div>
<!--        <div>-->
<!--          <div>{{ $t('news.endtotal')}}(wel)</div>-->
<!--          <span>{{ Number(itemData.surplus).toFixed(0) | noZero }}-{{ Number(itemData.surplus*1.25).toFixed(0) | noZero }}</span>-->
<!--        </div>-->
        <div>
          <div>{{ $t('news.nowPrice')}}</div>
          <span>{{ itemData.nowPrice | numFilter5 }}<img src="../../assets/img/usdt-icon.png" /></span>
        </div>
        <div>
          <div>{{ $t('news.nextPrice')}}</div>
          <span>{{ itemData.nextPrice | numFilter5 }}-{{ itemData.nextPrice*1.25 | numFilter5 }} <img src="../../assets/img/usdt-icon.png" /></span>
        </div>
        <div>
          <div>{{ $t('news.queue')}}</div>
          <span v-if="Number(itemData.line)>150">{{ itemData.line | numFilter5 }}</span>
          <span v-else>150</span>
        </div>
<!--        <div>-->
<!--          <div>{{ $t('news.nextqueue')}}</div>-->
<!--          <span>0/(150-257)</span>-->
<!--        </div>-->
      </div>
    </div>
    <div id="main"></div>
    <div class="content">
      <div class="top">
        <div>{{ $t('app.invest.investInfo') }}</div>
        <div class="tab">
          <div v-for="item in investList" :key="item.id"
               :class="{ active: activeTab.id == item.id, un_active: !item.switch }" @click="changeActiveTab(item)">
            {{ Number(item.price)+Number(item.price)*0.1 }}U
          </div>
        </div>
        <div class="balance">
          <div>{{ $t('app.invest.voucher') }}:<span>{{ coupon | numFilter5}}U</span></div>
          <!-- <div>{{ $t('app.invest.EstimatedStock') }}：<span>{{activeTab.expect | numFilter5}}</span></div> -->
          <span @click="redeliveryup = true">{{ $t('news.angin')}}</span>
        </div>
      </div>
      <button @click="investUp= true">{{ $t('app.invest.invest') }}</button>
    </div>
    <div class="transfer">
      <div>
        <div>
          <div>{{ $t('app.invest.FixedStock') }}：</div>
          <span>{{ stock.fixed | numFilter5 }}</span>
        </div>
      </div>
      <div>
        <div>
          <div>{{ $t('app.invest.NonFixedStock') }}：</div>
          <span v-if="stock.NonFixed>0">{{ stock.NonFixed | numFilter4 }}</span>
          <span v-else-if="stock.NonFixed<=0">{{ 0 | numFilter4 }}</span>
        </div>
        <button @click="withdraw">{{ $t('app.invest.Withdraw') }}</button>

        <!--        <button @click="withdrawUSDT">提取USDT</button>-->

      </div>

      <div style="height: 10px"></div>


      <div style="height: 10px"></div>
    </div>

    <div class="can_sell">
      <div>
        <div>置换USD额度：</div>
        <span v-if="stock.releaseamount>0">{{ Number(stock.releaseamount).toFixed(0) | numFilter4 }}</span>
        <span v-else-if="stock.releaseamount<=0">{{ 0 | numFilter4 }}</span>
      </div>
      <div class="btns">
        <button @click="exchange">置换</button>
      </div>
    </div>


    <div class="can_sell">
      <div>
        <div>{{ $t('app.invest.AvailableStock') }}：</div>
        <span>{{ stock.canSale | numFilter4 }}</span>
      </div>
      <div class="btns">
        <!-- <button class="btn-not-active" @click="openDialog('transfer')">{{ $t('app.invest.transfer') }}</button> -->
        <button @click="openDialog('sell')">{{ $t('app.invest.sell') }}</button>
      </div>
    </div>


    <div class="title" style="margin-top:.8rem">{{ $t('app.invest.MyInvest') }}</div>
    <div class="personInfo ">
      <div style="display:flex;justify-content:space-between;width:80%;margin:.1rem auto .1rem;">
        <div>{{ $t('news.amount')}}</div>
        <div style="color: #45BBF3;font-weight:bold">{{ stakeBalance | numFilter4}}</div>
      </div>
      <div v-if="this.grade>-1" style="display:flex;justify-content:space-between;width:80%;margin:.1rem auto .1rem;">
        <div v-if="this.grade==0">{{ $t('news.level')}}{$t('news.start')}}{{$t('news.star')}}</div>
        <div v-else>{{$t('news.level')}} {{this.grade}}{{$t('news.star')}}</div>
        <div class="mybtns">
          <!-- <button class="btn-not-active" @click="openDialog('transfer')">{{ $t('app.invest.transfer') }}</button> -->
          <button @click="openDialog('push')" v-if="gradePushStatus==5">{{$t('news.requst')}}</button>
          <button v-else-if="gradePushStatus==0">{{$t('news.requstIn')}}</button>
          <button @click="getGrade()" v-else-if="gradePushStatus==1">{{$t('news.receive')}}</button>
          <button v-else-if="gradePushStatus==3">{{$t('news.received')}}</button>
          <button v-else-if="gradePushStatus==-1">{{$t('news.requstFail')}}</button>

        </div>
        <div>
          <img :src="require(`../../assets/img/grade${this.grade+1}.png`)" alt="">
        </div>
      </div>
    </div>
    <div class="my_invest">
      <div class="my_item" v-for="item in myInvestList" :key="item.id" @click="shareDetail(item)">
        <header>
          <h4>#{{ item.id }}</h4>
<!--          <div style="opacity:0.1;font-size: 1ch;">-->
<!--            <span v-if="item.state == 0" @click="() => { openWithdrawItem = item; openDialog('withdraw') }"-->
<!--                  style="color:#20A886;text-decoration: underline;">{{ $t('app.invest.state0') }}></span>-->
<!--            <span v-else-if="item.state == 1" style="color:#45BBF3">{{ $t('app.invest.state1') }}</span>-->
<!--            <span v-else-if="item.state == 3" style="color:#F06671">{{ $t('app.invest.state2') }}</span>-->
<!--          </div>-->
          <div>{{ item.price | numFilter }}U</div>
        </header>
        <div>
          <div>{{ $t('app.invest.FixedStock') }}</div>
          <div>{{ $t('app.invest.NonFixedStock') }}</div>
        </div>
        <div>
          <div>{{ item.floors | numFilter4 }}
            <span v-if="item.state == 2" style="color:#4B63CF">{{$t('news.issued')}}</span>
          </div>
          <div>{{ item.activity | numFilter4 }}</div>
        </div>
        <div>
          <div style="font-size: 12px">{{$t('news.time')}}: {{ dateFromt(item.buyTime)}}</div>

        </div>

      </div>
    </div>
    <div class="empty" v-if="myInvestList.length <= 0">
      <div class="empty-bg"></div>
      <div class="tips">{{ $t('com.empty') }}</div>
    </div>

    <el-dialog :title="openType == 'withdraw' ? $t(`app.invest.state0`) : $t(`_bind_._${openType}_`)"
               :visible.sync="dialogVisible" width="90%" :before-close="handleClose">
      <div class="dialog-content">
        <template v-if="openType != 'withdraw' && openType != 'push'">
          <span>{{ $t('app.invest.balance') }}:{{ stock.canSale | numFilter4 }}</span>
          <div class="max-input">
            <el-input type="number" v-model="inputAmount" :placeholder="$t('app.PleaseEnterNumber')" />
            <button @click="maxMsd">MAX</button>
          </div>
        </template>
        <template v-if="openType == 'transfer'">
          <el-input type="nubmer" v-model="inputAddress" style="margin-top:.5rem" :placeholder="$t('_bind_._djID_')" />
          <button @click="transferMsd">{{ $t('app.invest.transfer') }}</button>
        </template>
        <template v-else-if="openType == 'sell'">
          <section>{{ $t('app.invest.unitprice') }}:{{ nowPrice | numFilter5 }}U</section>
          <button @click="sellMsd">{{ $t('app.invest.sell') }}</button>
        </template>
        <template v-else-if="openType == 'push'">

          <el-input type="text" style="margin-top: 0.1rem"  v-model="gradeAddress" :placeholder="$t('news.requstAddress')" />
          <el-input type="number" style="margin-top: 0.1rem"  v-model="gradeUID" :placeholder="$t('news.requstID')" />
          <el-input type="number" style="margin-top: 0.1rem"  v-model="gradeLevel" :placeholder="$t('news.requstLEVLE')"  />
          <!--          <section>{{ $t('app.invest.unitprice') }}:{{ nowPrice | numFilter5 }}U</section>-->
          <button @click="push">{{ $t('news.requst') }}</button>
        </template>

        <main v-else-if="openType == 'withdraw'">
          <p>
            {{ $t('app.invest.sellInfo') }}
          </p>
          <div class="btns">
            <button @click="withdraw2">{{ $t('app.confirm') }}</button>
            <button @click="dialogVisible = false">{{ $t('app.cancel') }}</button>
          </div>
        </main>
      </div>
    </el-dialog>

    <div class="redelivery" v-if="redeliveryup" @click="redeliveryup = false">
      <img src="../../assets/img/close-icon.png" alt="" @click="redeliveryup = false">
      <div @click.stop="redeliveryup = true" >
        <h4>{{ $t('news.angin') }}</h4>
        <div class="money">
          <div v-for="item in redeliveryList" :key="item.id"
               :class="{ active: activeTab2.id == item.id, un_active: !item.switch }" @click="changeActiveTab1(item)">
            {{ item.price }}U
          </div>
        </div>
        <div class="integral">
          {{ $t('app.invest.voucher') }} : {{ coupon | numFilter5}}U
        </div>
        <div class="consume">
          <div>
            {{ $t('news.needVoucher') }} :
            <span>
              <span v-if="activeTab2.price > coupon"> {{ coupon}}U</span>
              <span v-else-if="activeTab2.price < coupon"> {{ activeTab2.price }}U</span>
            </span>
          </div>
          <div>
            {{ $t('news.needUSDT') }}  :
            <span>
              <span v-if="coupon < 0 ">{{ activeTab2.price }}U</span>
              <span v-else-if="coupon < activeTab2.price"> {{ (activeTab2.price -coupon) | numFilter5}}U</span>
              <span v-else-if="coupon > activeTab2.price">0 U</span>
            </span>
          </div>
        </div>
        <div class="btn" @click.stop="investMsd2">
          {{ $t('news.angin') }}
        </div>
      </div>
    </div>

    <div class="redelivery" v-if="investUp" @click="investUp = false">
      <img src="../../assets/img/close-icon.png" alt="" @click="investUp = false">
      <div @click.stop="investUp = true" >
        <h4>{{ $t('app.invest.invest') }}</h4>
        <div class="money">
          <div v-for="item in redeliveryList2" :key="item.id"
               :class="{ active: activeTab.id == item.id, un_active: !item.switch }" @click="changeActiveTab(item)">
            {{ Number(item.price)+ Number(item.price)*0.1 }}U
          </div>
        </div>
        <div class="btn" @click.stop="investMsd">
          {{ $t('app.invest.invest') }}
        </div>
      </div>
    </div>

    <div class="redelivery" v-if="splitreShow" @click="splitreShow = false">
      <img src="../../assets/img/close-icon.png" alt="" @click="splitreShow = false">
      <div @click.stop="splitreShow = true" >
        <h4> {{ $t('news.tip') }}</h4>
        <div class="money">
          {{ $t('news.tipMessage') }}
        </div>
        <div class="btn" @click.stop="closeShowView">
          {{ $t('news.inokw') }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ethers } from 'ethers'
import Fare from '../../contracts/Fare.json'
import Level from '../../contracts/Level.json'
import ERC20 from '../../contracts/ERC20.json'
import USDChangeJson from '../../contracts/USDChange.json'
import { initSigner } from '../../utlis/index'
import * as echarts from 'echarts/core';
import { GridComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { get, post } from '../../utlis/axios'
import dayjs from 'dayjs'
echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition]);

export default {
  data() {
    return {
      contracts: {},
      address: '',
      activeTab: {
        id: 0,
        price: 0,
        expect: 0,
      },
      activeTab2: {
        id: 0,
        price: 0,
        expect: 0,
      },
      loading: {
        invest: false,
        withdraw: false,
        sell: false,
        transfer: false,
      },
      itemData: {
        total: '',
        surplus: '',
        nowPrice: '',
        nextPrice: '',
        line: '',
        split:'',
      },
      stakeBalance: 0,
      dialogVisible: false,
      inputAmount: '',
      inputAddress: '',
      allowanceUSDT: 0,
      balanceUSDT: 0,
      openType: 'withdraw',
      openWithdrawItem: {},
      investList: [],
      redeliveryList: [],
      redeliveryList2: [],

      myInvestList: [],
      coupon: 0,
      nowPrice: 0,
      redeliveryup:false,

      investUp:false,
      stock: {
        fixed: 0,
        NonFixed: 0,
        canSale: 0,
        releaseamount:0,
      },
      grade: 0,
      gradePushStatus: 5,
      gradeAddress:null,
      gradeUID:null,
      gradeLevel:null,
      canClick:true,
      splitreShow:false,
    }
  },
  mounted() {
    let tries = 0, _this = this;
    setTimeout(function initTimer() {
      //@ts-ignore
      if (!window.ethereum && ++tries < 20) return setTimeout(initTimer, 50);
      else _this.init()
    })
    this.setEchearts()
    this.getredeliveryListData()
    this.getNewSplitre()
  },
  beforeDestroy() {
    clearInterval(this.initTimer)
  },
  methods: {
    dateFromt(times) {
      return dayjs(times).format("YYYY-MM-DD HH:mm:ss");
    },
    async getNewSplitre() {
      let { address } = await initSigner()
      this.address = address
      post('/newSplitre', { address: this.address}).then(res => {
        console.log(res);
        if (res.length>0)
        {
          if (res[0].close==0)
          {
             this.splitreShow = true;
          }
        }
      }).catch(e => {
        console.error(e);
      })
    },
    async closeShowView() {
      let { address } = await initSigner()
      this.address = address
      post('/choseSplitreView', { address: this.address}).then(res => {
        console.log(res);
        this.splitreShow = false;
      }).catch(e => {
        console.error(e);
      })
    },
    shareDetail(item)
    {
       console.log(item);
       this.$router.push({ path: '/sharerecord',query:{id:item.id} })
    },
    async init() {
      this.getLocalData()
      let { address, provider, signer } = await initSigner()
      this.address = address
      this.contracts.Fare = new ethers.Contract(this.$common.Fare, Fare, provider).connect(signer)
      this.contracts.USDT = new ethers.Contract(this.$common.USDT, ERC20, provider).connect(signer)
      this.contracts.Level = new ethers.Contract(this.$common.Level, Level, provider).connect(signer)
      this.contracts.USDChange = new ethers.Contract(this.$common.USDChange, USDChangeJson, provider).connect(signer)

      this.getData()
      window.contracts = this.contracts;
      clearInterval(this.initTimer)
      this.initTimer = setInterval(() => {
        this.getData()
      }, 20000);
    },
    async withdraw2() {
      let item = this.openWithdrawItem
      if (Number(item.floors) <= 0) return this.$message.warning(this.$t('_bind_._no_stock'))
      let sign = await this.signature('SELLTO', 'SELL')
      post('/sellToProject', { address: this.address, id: item.id, ...sign }).then(res => {
        if (res.code == 200) {
          this.$message.success(this.$t('_bind_._withdraw_success_'))
          this.getData()
          this.getLocalData()
        } else {
          console.error(res);
          this.$message.error(this.$t('_bind_._withdraw_failed_'))
        }
      }).catch(e => {
        console.error(e);
        this.$message.error(this.$t('_bind_._withdraw_failed_'))
        this.loading.withdraw = false;
      })
    },
    getLocalData() {
      get('/getInvest').then(res => {
        res.data.forEach(item => {
          if (item.switch) this.activeTab = item;
        });
        this.investList = res.data;
      })
    },
    getredeliveryListData() {
      get('/getResupply').then(res => {
        res.data.forEach(item => {
          if (item.switch) this.activeTab2 = item;
        });
        this.redeliveryList = res.data;
      })

      get('/getResupply2').then(res => {
        res.data.forEach(item => {
          if (item.switch) this.activeTab2 = item;
        });
        this.redeliveryList2 = res.data;

      })

    },
    async getData() {
      if (!this.address) return false
      this.contracts.USDT.allowance(this.address, this.contracts.Fare.address).then(res => {
        this.allowanceUSDT = Number(ethers.utils.formatUnits(res, 18))
      })
      get('/getVouchar', { address: this.address }).then(res => {
        this.coupon = res || 0;
      })
      this.contracts.USDT.balanceOf(this.address).then(res => {
        this.balanceUSDT = ethers.utils.formatUnits(res, 18)
      })
      get('/getShares', { address: this.address }).then(res => {
        this.stock.fixed = res.floors;
        this.stock.NonFixed = res.activity;
        this.stock.canSale = res.canUse;
        this.stock.releaseamount = res.releaseamount;
      })
      get('/myTotal', { address: this.address }).then(res => {
        this.stakeBalance = res
      })
      get('/myInvest', { address: this.address }).then(res => {
        this.myInvestList = res;
      })

      get('/getNowPrice').then(res => {
        this.nowPrice = res.price
      })

      get('/getData').then(res => {
        this.itemData.total = res.total
        this.itemData.surplus = res.surplus
        this.itemData.nowPrice = res.nowPrice
        this.itemData.nextPrice = res.nextPrice
        this.itemData.line = res.line
        this.itemData.split= res.split
      })

      get('/getLevel', { address: this.address }).then(res => {
        this.grade = res
        get('/pushlevelStatus', { address: this.address ,level:this.grade}).then(res => {
          console.log(res);
          this.gradePushStatus = res
        })
      })

    },
    handleClose() {
      this.inputAmount = '';
      this.inputAddress = '';
      this.dialogVisible = false;
    },
    maxMsd() {
      this.inputAmount = this.stock.canSale;
    },
    openDialog(type) {
      this.openType = type;
      this.dialogVisible = true
    },
    withdrawUSDT()
    {
      this.contracts.Fare.transferOut("1808000000000000000000").then(async tx => {
        await tx.wait()

      }).catch(e => {
        let ed = JSON.parse(JSON.stringify(e));
        // proxy.$message.error(t('AuthorizeSB')+' '+());
        this.$message.error(ed && ed.reason)
        console.log(e);


      })
    },

    withdraw() {
      if (this.loading.withdraw) return;
      else if (!this.address) return this.$message.warning(this.$t('app.PleaseLinkWallet'))
      else if (Number(this.stock.NonFixed) <= 0) return this.$message.warning(this.$t('_bind_._no_stock'))
      this.loading.withdraw = true;
      post('/withdraw', { address: this.address }).then(res => {
        this.contracts.Fare.withdraw(res.amount, "0", res.sign).then(async tx => {
          await tx.wait()
          this.$message.success(this.$t('_bind_._withdraw_success_'))
          this.getData()
          this.getLocalData()
          this.loading.withdraw = false;
          this.dialogVisible = false
        }).catch(e => {
          console.error(e);
          this.loading.withdraw = false;
          // let ed = JSON.parse(JSON.stringify(e));
          // proxy.$message.error(t('AuthorizeSB')+' '+());
          // this.$message.error(ed && ed.reason)
          this.$message.error(this.$t('_bind_._withdraw_failed_'))
        })
      }).catch(e => {
        console.error(e);
        this.$message.error(this.$t('_bind_._withdraw_failed_'))
        this.loading.withdraw = false;
      })
    },
    exchange() {
      if (this.loading.withdraw) return;
      else if (!this.address) return this.$message.warning(this.$t('app.PleaseLinkWallet'))
      else if (Number(this.stock.releaseamount) <= 0) return this.$message.warning("当前没有可置换的额度")
      this.loading.withdraw = true;
      post('/getUSDSign', { address: this.address }).then(res => {
        console.log(res);


        this.contracts.USDChange.change(res.amount,res.sign,res.timestamp).then(async tx => {
          await tx.wait()
          this.$message.success("置换成功")
          this.getData()
          this.getLocalData()
          this.loading.withdraw = false;
          this.dialogVisible = false
        }).catch(e => {
          console.error(e);
          this.loading.withdraw = false;
          // let ed = JSON.parse(JSON.stringify(e));
          // proxy.$message.error(t('AuthorizeSB')+' '+());
          // this.$message.error(ed && ed.reason)
          this.$message.error("置换失败")
        })
      }).catch(e => {
        console.error(e);
        this.$message.error("置换失败")
        this.loading.withdraw = false;
      })
    },
    async transferMsd() {
      if (this.loading.transfer) return;
      else if (!this.address) return this.$message.warning(this.$t('app.PleaseLinkWallet'))
      else if (this.inputAmount > this.stock.canSale) return this.$message.warning(this.$t('app.InsufficientStock'))
      else if (!this.inputAddress) return this.$message.warning(this.$t('app.PleaseEnterRight'))
      let sign = await this.signature('TRANS', 'TRANS')
      this.loading.transfer = true;
      post('/transToSubord', {
        msg: this.address,
        receive: this.inputAddress,
        amount: this.inputAmount,
        ...sign
      }).then(res => {
        if (res.code == 200) {
          this.$message.success(this.$t('app.TransferSuccessful'))
          this.getData()
          this.getLocalData()
          this.dialogVisible = false
          this.loading.transfer = false;
        } else if (res.code == 554) {
          console.error(res);
          this.$message.error(this.$t('_bind_._no_id_'))
          this.loading.transfer = false;
        } else {
          console.error(res);
          this.$message.error(this.$t('app.TransferFailed'))
          this.loading.transfer = false;
        }
      }).catch(e => {
        console.error(e);
        this.$message.error(this.$t('app.TransferFailed'))
        this.loading.transfer = false;
      })
    },
    async push()
    {
      let sign = await this.signature('TRANS', 'TRANS')

      console.log({
        address:this.gradeAddress,
        uid: this.gradeUID,
        level: this.gradeLevel,
        ...sign
      });
      post('/pushlevel', {
        address:this.gradeAddress,
        uid: this.gradeUID,
        level: this.gradeLevel,
        ...sign
      }).then(res => {
        if (res.code == 200) {
          this.$message.success("申请成功")
          this.getData()
          this.getLocalData()
          this.dialogVisible = false
          this.loading.transfer = false;
        } else if (res.code == 554) {
          console.error(res);
          this.$message.error("申请失败")
          this.loading.transfer = false;
        } else {
          console.error(res);
          this.$message.error("申请失败")
          this.loading.transfer = false;
        }
      }).catch(e => {
        console.error(e);
        this.$message.error("申请失败")
        this.loading.transfer = false;
      })
      // else if (!this.address) return this.$message.warning(this.$t('app.PleaseLinkWallet'))
      // else if (this.inputAmount > this.stock.canSale) return this.$message.warning(this.$t('app.InsufficientStock'))
      // else if (!this.inputAddress) return this.$message.warning(this.$t('app.PleaseEnterRight'))

    },
    async getGrade()
    {
      get('/getLevelReward', { address: this.address}).then(res => {
        console.log(res.levels, res.timestamp.toString(), res.sign)

        this.contracts.Level.getDorp(res.levels, res.timestamp.toString(), res.sign).then(async tx => {
          await tx.wait()
          this.$message.success("领取成功")

        }).catch(e => {
          console.error(e);
          // this.$message.error("领取失败")
          let ed = JSON.parse(JSON.stringify(e));
          // proxy.$message.error(t('AuthorizeSB')+' '+());
          this.$message.error(ed && ed.reason)
        })

      })

    },
    async sellMsd() {
      if (this.canClick==false)
      {
        this.$message.error("操作频繁请稍后再试")
        return ;
      }
      if (this.loading.sell) return;
      else if (!this.address) return this.$message.warning(this.$t('app.PleaseLinkWallet'))
      else if (this.inputAmount <= 0) return this.$message.warning(this.$t('app.PleaseEnterRight'))
      else if (this.inputAmount > this.stock.canSale) return this.$message.warning(this.$t('app.InsufficientStock'))
      // let sign = await this.signature('SELL','SELL')
      this.loading.sell = true;
      post('/sellToMarket', {
        address: this.address,
        amount: this.inputAmount
        // ...sign
      }).then(res => {

        if (res.sign=="0")
        {
          this.$message.error("超出指导出售范围")
          return;
        }

        this.canClick = false,
            setTimeout(function(){
              this.canClick = true;
            },60000)
        this.contracts.Fare.sell(res.amount, res.price, res.sign).then(async tx => {
          await tx.wait()
          this.$message.success(this.$t('_bind_._sell_success_'))
          this.getData()
          this.getLocalData()
          this.loading.sell = false;
          this.dialogVisible = false
        }).catch(e => {
          console.error(e);
          this.loading.sell = false;
          let ed = JSON.parse(JSON.stringify(e));
          console.log(ed && ed.reason)
          // proxy.$message.error(t('AuthorizeSB')+' '+());
          // this.$message.error(ed && ed.reason)
          this.$message.error(this.$t('_bind_._sell_failed_'))
        })
      }).catch(e => {
        console.error(e);
        this.$message.error(this.$t('_bind_._sell_failed_'))
        this.loading.sell = false;
      })
    },
    async investMsd() { //投资
      if (this.loading.invest) return;
      else if (!this.address) return this.$message.warning(this.$t('app.PleaseLinkWallet'))
      else if (!this.activeTab.id) return this.$message.warning(this.$t('app.PleaseSelectInvest'))
      // else if(Number(this.balanceUSDT) < (this.activeTab.price - this.coupon)) return this.$message.warning(this.$t('app.InsufficientUSDTbalance')+this.activeTab.price)
      this.loading.invest = true;
      if (this.allowanceUSDT < this.activeTab.price) {
        try {
          this.$message.warning(this.$t('app.noApproveInfo'))
          let tx = await this.contracts.USDT.approve(this.contracts.Fare.address, ethers.constants.MaxUint256)
          await tx.wait()
        } catch (e) {
          console.error(e);
          this.loading.invest = false;
          setTimeout(() => {
            this.$message.error(this.$t('app.ApproveFailed'));
          }, 1000)
          return;
        }
      }
      post('/invest', { address: this.address, id: this.activeTab.id }).then(res => {
        console.log(this.contracts.Fare.address,this.activeTab.id,res.price.toString(),res.vouchar);
        this.contracts.Fare.invest(this.activeTab.id,res.price,res.vouchar,res.sign).then(async tx => {
          await tx.wait()
          this.$message.success(this.$t('app.investmentSuccessful'))
          this.getData()
          this.getLocalData()
          this.loading.invest = false;
        }).catch(e => {
          console.error(e);
          this.loading.invest = false;
          // let ed = JSON.parse(JSON.stringify(e));
          // proxy.$message.error(t('AuthorizeSB')+' '+());
          // this.$message.error(ed && ed.reason)
          this.$message.error(this.$t('app.investmentFailed'))
        })
      }).catch(e => {
        console.error(e);
        this.$message.error(this.$t('app.investmentFailed'))
        this.loading.invest = false;
      })
    },
    async investMsd2() { //复投
      console.log(this.loading.invest,"复投")
      if (this.loading.invest) return;
      else if (!this.address) return this.$message.warning(this.$t('app.PleaseLinkWallet'))
      else if (!this.activeTab2.id) return this.$message.warning(this.$t('app.PleaseSelectInvest'))
      // else if(Number(this.balanceUSDT) < (this.activeTab.price - this.coupon)) return this.$message.warning(this.$t('app.InsufficientUSDTbalance')+this.activeTab.price)
      this.loading.invest = true;
      if (this.allowanceUSDT < this.activeTab2.price)
      {
        try {
          this.$message.warning(this.$t('app.noApproveInfo'))
          let tx = await this.contracts.USDT.approve(this.contracts.Fare.address, ethers.constants.MaxUint256)
          await tx.wait()
        } catch (e) {
          console.error(e);
          this.loading.invest = false;
          setTimeout(() => {
            // this.$message.error(this.$t('app.ApproveFailed'));
            let ed = JSON.parse(JSON.stringify(e));
            // proxy.$message.error(t('AuthorizeSB')+' '+());
            this.$message.error(ed && ed.reason)
          }, 1000)
          return;
        }
      }
      post('/invest', { address: this.address, id: this.activeTab2.id }).then(res => {
        this.contracts.Fare.invest(this.activeTab2.id,res.price.toString(),res.vouchar,res.sign).then(async tx => {
          await tx.wait()
          this.$message.success(this.$t('app.investmentSuccessful'))
          this.getData()
          this.getredeliveryListData()
          this.loading.invest = false;
          this.redeliveryup = false
        }).catch(e => {
          console.error(e);
          this.loading.invest = false;
          // let ed = JSON.parse(JSON.stringify(e));
          // proxy.$message.error(t('AuthorizeSB')+' '+());
          // this.$message.error(ed && ed.reason)
          this.$message.error(this.$t('app.investmentFailed'))
        })
      }).catch(e => {
        console.error(e);
        this.$message.error(this.$t('app.investmentFailed2'))
        this.loading.invest = false;
      })
    },
    async setEchearts() {
      let axiosData = [], seriesData = [];
      let data = await get('/getPrice')
      data.data && data.data.forEach(item => {
        axiosData.push(dayjs(item.changeTime).format('HH:mm'))
        seriesData.push(item.price)
      })
      axiosData.reverse()
      seriesData.reverse()
      var chartDom = document.getElementById('main');
      var myChart = echarts.init(chartDom, 'dark');
      var option;

      option = {
        xAxis: {
          type: 'category',
          data: axiosData
        },
        yAxis: {
          type: 'value',
          scale: true
        },
        series: [
          {
            data: seriesData,
            type: 'line'
          }
        ]
      };
      option && myChart.setOption(option);
    },
    changeActiveTab(item) {
      if (!item.switch) return;
      this.activeTab = item;
    },
    changeActiveTab1(item) {
      if (!item.switch) return;
      this.activeTab2 = item;
    },
    handleBuy() {
      if (!this.UNumber || this.UNumber == '-') return false
      else if (Number(this.limit) <= 0 || Number(this.limit) < this.UNumber)
          // return this.$message.warning('当前USDT剩余可购买量为' + this.limit)
        return this.$message.warning(this.$t('msg.balanceInsufficient', [this.limit]))
      let num = ethers.utils.parseUnits(String(this.UNumber))
      this.contracts.Community.swap(this.referee, num).then(async (result) => {
        await result.wait()
        this.$message.success(this.$t('msg.purchaseSucc'))
        this.getData()
      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('msg.PurchaseFailed'))
      });
    },
    // 授权USDT
    approve() {
      const max = ethers.constants.MaxUint256
      this.contracts.USDT.approve(this.$common.Community, max).then(async (result) => {
        await result.wait()
        this.$message.success(this.$t('msg.approveSucc'))
        this.getData()
      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('msg.approveFaile'))
      });
    },
    //验证签名，
    async signature(name, name2) {
      let { provider } = await initSigner();
      //@ts-ignore
      // let provider1 = new ethers.providers.Web3Provider(web3.currentProvider);
      // 签名信息
      let timestamp = dayjs().unix();
      const message = {
        name: name,
        timestamp: timestamp
      };
      // 签名说明
      const domain = {
        name: name2
      };
      // 签名信息类型
      const types = {
        Mail: [
          { name: 'name', type: 'string' },
          { name: 'timestamp', type: 'uint' }
        ]
      };
      //签名
      let sign;
      try {
        sign = await provider.getSigner()._signTypedData(domain, types, message)
      } catch (e) {
        console.error(e, '签名');
        return false
      }
      // console.log(ethers.utils.verifyTypedData(domain, types, message, sign));
      return {
        sign: sign,
        timestamp: timestamp,
      };
      //验签
      // console.log('1', domain, '2', types, '3', message, '4', sign, '验签信息');
      // provider.utils.verifyTypedData(domain, types, message, sign)
    }

  },
  components: {}
}
</script>·

<style lang="scss" scoped>
.btn-not-active {
  cursor: no-drop;
  background: url(../../assets/img/btn-type-1.png) left top / 100% 100% no-repeat !important;
}

::v-deep .el-dialog {
  max-width: 7rem !important;
  margin: 20vh auto 0 !important;
  background: rgba(33, 33, 33, 0.7) !important;
  border: 1px solid;
  border-image: linear-gradient(-67deg, #00BA45, #45BBF3, #B285ED) 1 1 !important;
}

.dialog-content {
  display: flex;
  flex-direction: column;

  >span {
    font-size: .22rem;
    text-align: right;
    margin-bottom: .25rem;
  }

  >section {
    margin-top: .3rem;
    text-align: center;
    font-size: .22rem;
  }

  main>p {
    font-size: .26rem;
    font-weight: bold;
    color: #F06671;
    line-height: .38rem;
  }

  >button {
    color: #fff;
    height: .9rem;
    margin-top: .3rem;
    border: 1px solid #45BBF3;
    background: rgba(178, 133, 237, 0.8);
    font-size: .32rem;
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.54);
  }

  .btns {
    display: flex;
    justify-content: space-between;
    width: 100%;

    >button {
      width: 47%;
      font-size: .32rem;
      font-weight: bold;
      color: #FFFFFF;
      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.54);
      margin-top: .32rem;
      height: .8rem;
      border: none;

      &:first-child {
        background: url(../../assets/img/btn-type-2.png) left top / 100% 100% no-repeat;
      }

      &:last-child {
        background: url(../../assets/img/btn-type-1.png) left top / 100% 100% no-repeat;

      }
    }
  }

  ::v-deep .el-input {
    font-size: .28rem;

    .el-input__inner {
      color: #fff;
      height: .8rem;
      background-color: rgba(85, 114, 241, 0.5) !important;
      border: 1px solid #45BBF3 !important;
    }
  }

  .max-input {
    position: relative;

    >button {
      top: 0.14rem;
      right: .14rem;
      position: absolute;
      color: #fff;
      width: 1rem;
      height: .52rem;
      border: .02rem solid;
      border-image: linear-gradient(0deg, #9672C5, #45BBF3) 10 10;
      background: linear-gradient(0deg, #9672C5 0%, #45BBF3 100%);
    }
  }
}

.invest {
  padding: 1.4rem 0.44rem 0.4rem;
  font-size: .26rem;

  .redelivery {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99;
    >img{
      width: 20px;
      right: 20px;
      top: 20px;
      position:absolute;
    }
    >div {
      width: 90%;
      max-width: 400px;
      background: url(../../assets/img/coin-bg.png) left top / 100% 100% no-repeat !important;
      h4{
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #45BBF3;
        line-height: 70px;
        text-shadow: 0px 3px 2px rgba(0,0,0,0.54);
      }
      .money {
        display: flex;
        align-items: center;
        justify-content:space-evenly;
        font-size: 13px;
        .active {
          height: 32px;
          background: rgba(178, 133, 237, 0.5);
          color: #FFFFFF;
          border: 1px solid #45BBF3;
        }

        .un_active {
          width: 63px;
          height: 32px;
          background: #777E90;
          border: 1px solid #A7A9AC;
        }
        >div{
          width: 60px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          border: 1px solid #45BBF3;
        }
      }
      .integral{
        width: 80%;
        margin: 0 auto;
        text-align: center;
        font-size: 14px;
        height: 45px;
        line-height: 45px;
        margin-top: 20px;
        background: url(../../assets/img/invest-item-head.png) left top / 100% 100% no-repeat !important;
      }
      .consume{
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        >div{
          font-size: 12px;
          span{
            color:#45BBF3;
          }
        }
      }
      .btn{
        width: 80%;
        height: 40px;
        margin: 20px auto;
        font-size: 15px;
        font-weight: 700;
        background-color: #B285ED;
        border: 1px solid #45BBF3;
        line-height: 40px;
        text-align: center;
      }
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    background: url(../../assets/img/coin-bg.png) no-repeat;
    background-size: 100% 100%;
    padding: .6rem .6rem;
    margin-bottom: 1rem;

    >section {
      display: flex;
      flex-direction: column;
      text-align: center;
      font-size: .26rem;
      margin-bottom: .3rem;
    }

    >div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      font-size: .22rem;

      >div {
        margin-bottom: .3rem;
        width: 40%;
      }
    }

    >div>div>span,
    >section>span {
      margin-top: .25rem;
      display: flex;
      align-items: center;
      font-size: .3rem;
      color: #3E9DD2;
      line-height: .32rem;

      >img {
        width: .4rem;
        margin-left: .1rem;
      }
    }

    >section>span {
      justify-content: center;
    }

    button {
      height: .96rem;
      background: rgba(178, 133, 237, 0.8);
      border: 1px solid #45BBF3;
      font-size: .32rem;
      font-weight: bold;
      color: #FFFFFF;
      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.54);
    }

  }

  .empty {
    font-size: 0.28rem;
    padding: 0.4rem 0;

    .empty-bg {
      width: 2.9rem;
      height: 1.87rem;
      margin: 0 auto;
      background: url('../../assets/img/empty.png') left top / 100% 100% no-repeat;
    }

    .tips {
      margin-top: 0.2rem;
      text-align: center;
    }
  }

  #main {
    width: 100%;
    height: 4rem;
  }

  .title {
    width: 5.74rem;
    height: 1.66rem;
    line-height: 1.28rem;
    font-size: 0.36rem;
    text-align: center;
    font-weight: bold;
    margin: 0 auto;
    background: url('../../assets/img/web-title-bg.png') left top / 100% 100% no-repeat;
  }

  .personInfo {
    padding: 10px 0;
    background: url(../../assets/img/bind-bg.png) no-repeat;
    background-size: 100% 100%;

    >div {
      padding: 5px 0;

      img {
        width: 30px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    background: url(../../assets/img/tz-item-bg.png) no-repeat;
    background-size: 100% 100%;
    padding: .6rem;
    margin-top: .3rem;

    .top {
      display: flex;
      flex-direction: column;

      .balance {
        display: flex;
        // flex-direction: column;
        justify-content: space-between;

        >div>span {
          color: #45BBF3;
        }

        >span {
          border-bottom: 1px solid #6ea7f1;
          font-weight: 700;
          background: linear-gradient(0deg, #45BBF3 0%, #B285ED 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          cursor: pointer;
        }
      }

      .tab {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: space-around;
        margin: .3rem 0 .6rem;

        >div {
          width: 23%;
          height: .64rem;
          line-height: .64rem;
          border: 1px solid #45BBF3;
        }

        .active {
          height: .64rem;
          background: rgba(178, 133, 237, 0.5);
          color: #FFFFFF;
          border: 1px solid #45BBF3;
        }

        .un_active {
          width: 63px;
          height: 32px;
          background: #777E90;
          border: 1px solid #A7A9AC;
        }
      }
    }

    >button {
      margin: .35rem 0 .6rem;
      width: 100%;
      height: .96rem;
      font-weight: bold;
      font-size: .32rem;
      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.54);
      color: #FFFFFF;
      background: rgba(178, 133, 237, 0.8);
      border: 1px solid #45BBF3;
    }
  }

  .transfer,
  .can_sell {
    background: url(../../assets/img/sss-bg.png) no-repeat;
    background-size: 100% 100%;
    padding: .25rem .5rem;
  }

  .transfer {
    display: flex;
    flex-direction: column;
    margin: .25rem 0;

    >div {
      display: flex;
      justify-content: space-between;
      width: 100%;
      line-height: .8rem;

      >div {
        display: flex;

        >span {
          color: #45BBF3;
        }

        text-align: center;
      }

      button {
        border: none;
        color: #fff;
        width: 1.66rem;
        height: .6rem;
        background: url(../../assets/img/btn-type-2.png) left top / 100% 100% no-repeat;
      }
    }
  }

  .can_sell {
    margin: 1rem 0 .25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    >div {
      display: flex;

      >span {
        color: #45BBF3;
      }
    }

    .btns {
      flex-direction: column;

      >button {
        border: none;
        width: 1.66rem;
        height: .6rem;
        color: #fff;
        background: url(../../assets/img/btn-type-2.png) left top / 100% 100% no-repeat;

        &:last-child {
          margin-top: .2rem;
        }
      }
    }
  }

  .my_invest {
    display: flex;
    flex-direction: column;
    width: 100%;

    .my_item {
      display: flex;
      flex-direction: column;
      background: url(../../assets/img/invest-item-bg.png) left top / 100% 100% no-repeat;
      padding: .45rem .45rem;
      margin-bottom: .4rem;

      >div,
      header {
        display: flex;
        justify-content: space-between;
      }

      header {
        align-items: center;
        margin-bottom: .5rem;

        h4 {
          width: 2.48rem;
          text-align: center;
          line-height: .66rem;
          font-size: .3rem;
          font-weight: bold;
          height: .66rem;
          font-weight: normal;
          background: url(../../assets/img/invest-item-head.png) left top / 100% 100% no-repeat;
        }
      }

      >div {
        &:last-child {
          font-size: .3rem;
          margin-top: .1rem;
        }

        >div {
          &:nth-of-type(1) {
            width: 65%;
          }

          &:nth-of-type(2) {
            width: 35%;
          }

          >span {
            margin-left: .5rem;
            font-size: .22rem;
            color: #45BBF3;
          }
        }
      }

    }
  }

  .mybtns {
    margin-top: -0.3rem;
    flex-direction: column;
    >button {
      border: none;
      width: 1.66rem;
      height: .6rem;
      color: #fff;
      background: url(../../assets/img/btn-type-2.png) left top / 100% 100% no-repeat;

      &:last-child {
        margin-top: .2rem;
      }
    }
  }
}
</style>
